var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.resList),function(cat,catIndex){return _c('li',{key:catIndex,staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren(cat.name)
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon",class:cat.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(cat.name)+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},_vm._l((cat.resourceList),function(res,resIndex){return _c('ul',{key:resIndex,staticClass:"menu-subnav"},[(res.display)?_c('router-link',{attrs:{"to":res.url,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href,"target":res.target},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(res.name))])])])]}}],null,true)}):_vm._e()],1)}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }