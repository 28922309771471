<template>
  <ul class="menu-nav">

    <!-- 菜单开始 -->

    <li
      v-for="(cat, catIndex) in resList" :key="catIndex"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren(cat.name)
      }"
    >
      <a href="#" class="menu-link menu-toggle" >
        <i class="menu-icon" :class="cat.icon"></i>
        <span class="menu-text"> {{ cat.name }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav" v-for="(res, resIndex) in cat.resourceList" :key="resIndex">
          <router-link v-if="res.display"
            :to="res.url"
            v-slot="{ href, navigate, isActive, isExactActive }" custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate"   :target="res.target">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ res.name }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- 菜单结束 -->

  </ul>
</template>

<script>

import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTMenu",
  data() {
    return {
      resList: [],
      resMap: {}
    }
  },
  mounted() {
    this.resList = JwtService.getResList();
    this.initResMap();
  },
  methods: {
    // hasActiveChildren(match) {
    //   return this.$route["path"].indexOf(match) !== -1;
    // },
    hasActiveChildren(currName) {
      var path = this.$route["path"];
      var catName = this.resMap[path];
      // console.log(path, catName);
      return catName == currName;
    },
    initResMap() {
      for (var cat of this.resList) {
        for (var res of cat.resourceList) {
          this.resMap[res.url] = cat.name;
        }
      }
      // for (var c in this.resList) {
      //   var cat = this.resList[c];
      //   for (var r in cat.resourceList) {
      //     var res = cat.resourceList[r];
      //     this.resMap[res.url] = cat.name;
      //   }
      // }
    }
  }
};
</script>
